<template>
     <el-dialog title="销售批注" :visible.sync="pageShow" width="800px">
          <div class="tableOpa mb10">
               <el-button size="small" @click="add" type="primary">新增</el-button>
          </div>
          <!-- @selection-change="handleSelectionChange"  class="form_box" ref="multipleTable"-->
          <div class="table_wrap">
               <template>
                    <el-table :data="dataList" style="width: 100%"  height="320">
                         <!-- <el-table-column type="selection" width="46"></el-table-column> -->
                         <el-table-column prop="id" label="ID" width="70"></el-table-column>
                         <el-table-column label="批注用户" width="80">
						<template slot-scope="scope">
							{{ scope.row.user ? scope.row.user.nickname : '/' }}
						</template>
					</el-table-column>
                         <el-table-column prop="remark"   label="批注内容"></el-table-column>
                         <el-table-column prop="createtime_text"  width="160" label="批注时间"></el-table-column>
                         <el-table-column fixed="right" align="left" label="操作" width="140">
                              <template slot-scope="scope">
                                   <span @click="edit(scope.row.remark,scope.row.id)" class="cwBtn c_6d9fff pointer_ m_r10">修改</span>
                                   <span @click="del(scope.row.id)" class="cwBtn c_ff6f6f pointer_">删除</span>
                              </template>
                         </el-table-column>
                    </el-table>
               </template>
          </div>
          <!-- 批注子弹窗 -->
          <sell-node-modal ref="SellNodeModal" @ok="handlerNodeOk"></sell-node-modal>
     </el-dialog>
</template>

<script>
import {salesAnnotationsIndex,salesAnnotationsSaveData,salesAnnotationsDeleteData,salesAnnotationsEditData} from '@/api/index.js'
import sellNodeModal from '../sell-node-modal/sell-node-modal.vue';
export default {
  components: { sellNodeModal },
     data() {
          return {
               pageShow: false,
               dataList: [{
                    id: '001',
                    order_no: '112354578'
               }],
               subPageShow: false,//子弹窗
               content: "",
               curId:'',
               remark:'',
          };
     },
     methods: {
          open({id}){
               this.pageShow=true;
               this.curId=id;
               this.getData(id);
          },
          add() {
               this.$refs.SellNodeModal.open();
          },
          //获取销售批注列表
          getData(){
               salesAnnotationsIndex({orders_id:this.curId}).then(res=>{
                    console.log('获取销售批注列表',res)
                    this.dataList=res.data;
               })
          },
          // 获取批注内容
          handlerNodeOk(e){
               console.log('获取批注内容',e)
               this.remark=e.content;
               if(e.id){
                    console.log('编辑')
                    this.toEdit(this.remark,e.id);
               }else{
                  this.addData();  
               }
          },
          // 添加销售批注
          addData(){
               let params={
                    orders_id:this.curId,
                    remark:this.remark
               }
               salesAnnotationsSaveData(params).then(res=>{
                    console.log('添加销售批注',res)
                    this.$message.success('批注添加成功');
                    this.getData();
               })
          },
          // 删除批注
          del(_id){
               console.log('删除批注id',_id)
               salesAnnotationsDeleteData({id:_id}).then(res=>{
                    console.log('删除批注',res)
                    this.$message.success('批注删除成功');
                    this.getData();
               })
          },
          // 更新销售批注
          edit(_val,_id){
               this.$refs.SellNodeModal.open({id:_id,content:_val})
          },
          toEdit(_val,_id){
               let params={
                    id:_id,
                    remark:_val
               }
               salesAnnotationsEditData(params).then(res=>{
                    console.log('更新批注',res)
                    this.$message.success('批注更新成功');
                    this.getData();
               })
          }
     }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
     padding: 0 20px;
}
.table_wrap{ padding-bottom: 30px;}
</style>