// 导入axios
import Qs from 'qs';
import axios from 'axios';
import $publicFun from '../public/js/public.js';

// element组件
import {
	Message
} from 'element-ui';

// 进行一些全局配置
// 公共路由(网络请求地址)
let ajaxHttp = location.origin.indexOf('http') > -1 ? location.origin+'/' : 'http://gjwl.maoln.com/api/';
// let ajaxHttp = 'http://gjwl.maoln.com/api/';
let baseurl = '';
if (process.env.NODE_ENV === 'development') {
	// 本地
	baseurl = ajaxHttp;
} else {
	// 线上
	baseurl = '/api/';
}
axios.defaults.baseURL = baseurl;
// 请求响应超时时间
axios.defaults.timeout = 5000;

// 转换fromdata请求数据
// 利用axios拦截器来进行转换，只需要转换一次
axios.interceptors.request.use(function(config) {
	// 请求头转换为表单形式
	// config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
	config.headers['token'] = localStorage.getItem('token');
	config.transformRequest = [function(data) {
		// 在请求之前对data传参进行格式转换
		data = Qs.stringify(data);
		return data;
	}]
	return config;
}, function(error) {
	return Promise.reject(error);
})

// 封装自己的get/post方法
export default {
	get: function(path = '', data = {}) {
		return new Promise(function(resolve, reject) {
			axios.get(path, {
					params: data
				})
				.then(function(response) {
					// 登录失效
					if (response.data.error_code == 401) {
						Message.error(response.data.message);

						setTimeout(() => {
							$publicFun.get_link('/login', {})
						}, 1000)

						return
					}

					// 接口没通
					if (!response.data.code || response.data.code != 200) {
						Message.error(response.data.message);
						return
					}

					resolve(response.data);
				})
				.catch(function(error) {
					reject(error);
				});
		});
	},
	post: function(path = '', data = {}) {
		return new Promise(function(resolve, reject) {
			axios.post(path, data)
				.then(function(response) {
					// 登录失效
					if (response.data.error_code == 401) {
						Message.error(response.data.message);

						setTimeout(() => {
							$publicFun.get_link('/login', {})
						}, 1000)

						return
					}

					// 接口没通
					if (!response.data.code || response.data.code != 200) {
						Message.error(response.data.message);
						return
					}

					resolve(response.data);
				})
				.catch(function(error) {
					reject(error);
				});
		});
	}
};
