<template>
	<div class="screen_wrapper" v-if="modal_show">
		<div class="screen_content bg_fff popMain">
			<p class="font_16 bold_ flex04 screen_title">高级搜索</p>
			<div class="p_lr26 bg_fff" style="overflow: hidden;">
				<div class="screen_form">
					<div>
						<p class="font_14 m_tb12">报价日期：</p>
						<div class="flex04 screen_date">
							<div class="w_137">
								<el-date-picker v-enter-next-input type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
									v-model="submit_info.start_time"></el-date-picker>
							</div>
							<div class="screen_date_line"></div>
							<div class="w_137">
								<el-date-picker v-enter-next-input type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
									v-model="submit_info.end_time"></el-date-picker>
							</div>
						</div>
					</div>
					<div>
						<p class="font_14 m_tb12">订单编号：</p>
						<v-select v-enter-next-input :placeText="'请填写订单编号'" :type_num="1"
							:value_content="submit_info.order_no" @get_input_value="get_input_value"></v-select>
					</div>
					<div>
						<p class="font_14 m_tb12">{{ modal_num == 1 ? '供应商名称' : '客户名称' }}：</p>
						<v-select v-enter-next-input :placeText="modal_num == 1 ? '请填写供应商名称' : '请填写客户名称'" :type_num="2"
							:value_content="submit_info.company_name" @get_input_value="get_input_value"></v-select>
					</div>
					<div>
						<p class="font_14 m_tb12">负责人：</p>
						<v-select v-enter-next-input :placeText="'请填写负责人名称'" :type_num="3"
							:value_content="submit_info.linkman" @get_input_value="get_input_value"></v-select>
					</div>
					<div>
						<p class="font_14 m_tb12">运单编号：</p>
						<el-input placeholder="" v-model="submit_info.waybill_no" clearable></el-input>
					</div>
					<div>
						<p class="font_14 m_tb12">销售人员：</p>
						<el-input placeholder="" v-model="submit_info.sales_person" clearable></el-input>
					</div>
					<div>
						<p class="font_14 m_tb12">对账情况：</p>
						<div v-enter-next-input class="screen_choose_wrapper flex03">
							<div class="screen_choose_block flex01"
								:class="{ 'active': submit_info.reconciliation_id === 1 }">
								<i @click="submit_info.reconciliation_id = 1"></i>
								<p class="font_14">已确认</p>
							</div>
							<div class="screen_choose_block flex01"
								:class="{ 'active': submit_info.reconciliation_id === 0 }">
								<i @click="submit_info.reconciliation_id = 0"></i>
								<p class="font_14">未确认</p>
							</div>
						</div>
					</div>
					<div>
						<p class="font_14 m_tb12">开票情况：</p>
						<div class="screen_choose_wrapper flex03">
							<div class="screen_choose_block flex01"
								:class="{ 'active': submit_info.make_invoice === 1 }">
								<i @click="submit_info.make_invoice = 1"></i>
								<p class="font_14">已开票</p>
							</div>
							<div class="screen_choose_block flex01"
								:class="{ 'active': submit_info.make_invoice === 0 }">
								<i @click="submit_info.make_invoice = 0"></i>
								<p class="font_14">未开票</p>
							</div>
						</div>
					</div>
				</div>
				<div class="flex02 m_tb40">
					<div class="btn02" @click="get_reset">重置</div>
					<div class="btn02" @click="get_exit">退出</div>
					<div class="btn01" @click="get_inquire">查询</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import select03 from '../select/index04.vue';
import select05 from '../select/index05.vue';

export default {
	components: {
		'v-select': select03,
		'v-select05': select05
	},
	props: {
		modal_show: {
			type: Boolean
		},
		modal_num: {
			type: Number
		}
	},
	data() {
		return {
			submit_info: {
				start_time: '',
				end_time: '',
				order_no: '',
				company_name: '',
				linkman: '',
				reconciliation_id: '',
				make_invoice: '',
				waybill_no: '',//运单编号
				sales_person: '',//销售人员
			}
		}
	},
	mounted() {
		// document.addEventListener('click', (e) => {
		// 	if (e.target.className != 'popMain' && e.target.className=='screen_wrapper') {
		// 		this.get_int();
		// 		this.$emit('getExit')
		// 	}
		// });
		window.addEventListener("keydown", this.keyDown);
	},
	destroyed () {
		window.removeEventListener("keydown", this.keyDown, false);;
	},
	methods: {
		// 点击回车
		keyDown(e) {
			console.log('点击回车e',e)
			// 回车则执行登录方法 enter键的ASCII是13
			if (e.keyCode === 13) {
				this.get_inquire(); 
			}
		},
		get_input_value(e) {
			if (e.type_num == 1) this.submit_info.order_no = e.value;
			if (e.type_num == 2) this.submit_info.company_name = e.value;
			if (e.type_num == 3) this.submit_info.linkman = e.value;
		},
		get_int() {
			this.submit_info = {
				start_time: '',
				end_time: '',
				order_no: '',
				company_name: '',
				linkman: '',
				reconciliation_id: '',
				make_invoice: '',
				waybill_no: '',//运单编号
				sales_person: '',//销售人员
			}
		},
		// 重置
		get_reset() {
			this.get_int();
		},
		// 退出
		get_exit() {
			this.get_int();

			this.$emit('getExit')
		},
		// 查询
		get_inquire() {
			this.$emit('getInquire', this.submit_info);
			this.get_exit();
		}
	}
}
</script>

<style lang="less" scoped>
.screen_wrapper {
	top: 100px;
	left: 170px;
	right: 0;
	bottom: 0;
	z-index: 999;
	display: flex;
	position: fixed;
	overflow: scroll;
	justify-content: flex-end;
	background-color: rgba(0, 0, 0, 0.2);

	&::-webkit-scrollbar {
		//滚动条整体部分
		width: 0;
		height: 0;
	}

	.screen_content {
		width: 366px;

		.screen_title {
			height: 52px;
			border-bottom: 1px solid #D0D0D0;
		}
	}
}

.w_137 {
	width: 137px;
}

.screen_date {
	.screen_date_line {
		width: 17px;
		height: 1px;
		margin: 0 14px;
		background-color: #666666;
	}

	/deep/ .el-date-editor.el-input,
	/deep/ .el-date-editor.el-input__inner {
		width: 100%;
	}

	/deep/ .el-input__inner {
		border-radius: 10px;
		font-size: 12px;
	}
}

.screen_form {
	overflow: hidden;
	margin: 8px 0 0 0;
}

.screen_choose_block {
	&:first-child {
		margin-right: 52px;
	}

	&.active {
		i {
			background: url(../../assets/03@2x.png) no-repeat center;
			background-size: 100% 100%;
		}
	}

	i {
		width: 16px;
		height: 16px;
		display: block;
		margin-right: 8px;
		cursor: pointer;
		background: url(../../assets/02@2x.png) no-repeat center;
		background-size: 100% 100%;
	}
}</style>
