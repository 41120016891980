<template>
	<div class="screen_wrapper" v-if="modal_show">
		<div class="screen_content bg_fff popMain">
			<p class="font_16 bold_ flex04 screen_title">高级搜索</p>
			<div class="p_lr26 bg_fff" style="overflow: hidden;">
				<div class="screen_form">
					<div>
						<p class="font_14 m_tb12">订单编号：</p>
						<v-select04 v-enter-next-input :placeText="'请填写订单编号'" :type_num="0" :value_content="submit_info.order_no"
							@get_input_value="get_input_value"></v-select04>
						<div class="flex04 screen_date m_t15">
							<div class="w_137">
								<el-date-picker v-enter-next-input type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
									v-model="submit_info.start_time"></el-date-picker>
							</div>
							<div class="screen_date_line"></div>
							<div class="w_137">
								<el-date-picker v-enter-next-input type="date" value-format="yyyy-MM-dd" placeholder="选择日期"
									v-model="submit_info.end_time"></el-date-picker>
							</div>
						</div>
					</div>
					<div>
						<p class="font_14 m_tb12">运单编号：</p>
						<el-input v-enter-next-input placeholder="" v-model="submit_info.waybill_no" clearable></el-input>
					</div>
					<div>
						<p class="font_14 m_tb12">销售人员：</p>
						<el-input v-enter-next-input placeholder="" v-model="submit_info.sales_person" clearable></el-input>
					</div>
					<div>
						<p class="font_14 m_tb12">公司名称：</p>
						<v-select v-enter-next-input :list="kehu_list" :index="1" :value_name="company_id_name"
							@result="get_kehu_serch"></v-select>
					</div>
					<div>
						<p class="font_14 m_tb12">联系人：</p>
						<v-select v-enter-next-input :list="lxr_list" :index="2" :value_name="linkman_id_name"
							@result="get_kehu_serch"></v-select>
					</div>
				</div>
				<div class="flex02 m_tb40">
					<div class="btn02" @click="get_reset">重置</div>
					<div class="btn02" @click="get_exit">退出</div>
					<div class="btn01" @click="get_inquire">查询</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import select05 from '../select/index05.vue';
import select04 from '../select/index04.vue';

import {
	companyList,
	getAllLinkman,
} from '@/api/index';

export default {
	components: {
		'v-select': select05,
		'v-select04': select04
	},
	props: {
		modal_show: {
			type: Boolean
		},
		modal_type: {
			type: Number
		},
		partner_type: {
			type: Number
		}
	},
	data() {
		return {
			lxr_list: [],
			kehu_list: [],
			company_id_name: '',
			linkman_id_name: '',
			submit_info: {
				start_time: '',
				end_time: '',
				order_no: '',
				company_id: '',
				linkman_id: '',
				waybill_no: '',//运单编号
				sales_person: '',//销售人员
			},
		}
	},
	created() {
		this.getCompanyList();
	},
	mounted() {
		// document.addEventListener('click', (e) => {
		// 	if (e.target.className != 'popMain' && e.target.className == 'screen_wrapper') {
		// 		this.get_int();
		// 		this.$emit('getExit')
		// 	}
		// });
		window.addEventListener("keydown", this.keyDown);
	},
	destroyed () {
		window.removeEventListener("keydown", this.keyDown, false);;
	},
	methods: {
		// 点击回车
		keyDown(e) {
			console.log('点击回车e',e)
			// 回车则执行登录方法 enter键的ASCII是13
			if (e.keyCode === 13) {
				this.get_inquire(); 
			}
		},
		// 客户名称
		getCompanyList() {
			companyList({
				page: 1,
				limit: 100000,
				partner_type: this.partner_type
			}).then(res => {
				let list_ = res.data.data;

				list_.forEach((n, k) => {
					this.kehu_list.push({
						label: n.company_name,
						value: n.id
					})
				})
			})
		},
		// 根据企业id 获取联系人
		get_linkman_list(id) {
			getAllLinkman({
				company_id: id
			}).then(res => {
				let list_ = res.data;
				this.lxr_list = [];

				list_.forEach((n, k) => {
					this.lxr_list.push({
						label: n.username,
						value: n.id
					})
				})
			})
		},
		get_input_value(e) {
			if (e.type_num == 0) {
				this.submit_info.order_no = e.value;
			}
		},
		get_kehu_serch(e) {
			if (e.index === 1) {
				// 客户名称
				this.submit_info.company_id = e.value;
				this.company_id_name = e.label;
				this.get_linkman_list(this.submit_info.company_id);
				this.submit_info.linkman_id = '';
				this.linkman_id_name = '';
			}

			if (e.index === 2) {
				// 联系人
				this.submit_info.linkman_id = e.value;
				this.linkman_id_name = e.label;
			}
		},
		get_int() {
			this.company_id_name = '';
			this.linkman_id_name = '';

			this.submit_info = {
				start_time: '',
				end_time: '',
				order_no: '',
				company_id: '',
				linkman_id: '',
				waybill_no: '',//运单编号
				sales_person: '',//销售人员
			}
		},
		// 重置
		get_reset() {
			this.get_int();
		},
		// 退出
		get_exit() {
			this.get_int();
			this.$emit('getExit')
		},
		// 搜索
		get_inquire() {
			this.$emit('getInquire', this.submit_info);
			this.get_exit();
		}
	}
}
</script>

<style lang="less" scoped>
.screen_wrapper {
	top: 100px;
	left: 170px;
	right: 0;
	bottom: 0;
	z-index: 999;
	display: flex;
	position: fixed;
	overflow: scroll;
	justify-content: flex-end;
	background-color: rgba(0, 0, 0, 0.2);

	&::-webkit-scrollbar {
		//滚动条整体部分
		width: 0;
		height: 0;
	}

	.screen_content {
		width: 366px;

		.screen_title {
			height: 52px;
			border-bottom: 1px solid #D0D0D0;
		}
	}
}

.w_137 {
	width: 137px;
}

.screen_date {
	.screen_date_line {
		width: 17px;
		height: 1px;
		margin: 0 14px;
		background-color: #666666;
	}

	/deep/ .el-date-editor.el-input,
	/deep/ .el-date-editor.el-input__inner {
		width: 100%;
	}

	/deep/ .el-input__inner {
		border-radius: 10px;
		font-size: 12px;
	}
}

.screen_form {
	overflow: hidden;
	margin: 8px 0 0 0;
}

.screen_choose_block {
	&:first-child {
		margin-right: 52px;
	}

	&.active {
		i {
			background: url(../../assets/03@2x.png) no-repeat center;
			background-size: 100% 100%;
		}
	}

	i {
		width: 16px;
		height: 16px;
		display: block;
		margin-right: 8px;
		cursor: pointer;
		background: url(../../assets/02@2x.png) no-repeat center;
		background-size: 100% 100%;
	}
}</style>
