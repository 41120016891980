<template>
     <el-dialog title="财务批注" :visible.sync="pageShow" width="800px">
          <div class="tableOpa mb10">
               <el-button size="small" @click="add" type="primary">新增</el-button>
          </div>
          <!-- @selection-change="handleSelectionChange"  class="form_box" ref="multipleTable"-->
          <div class="table_wrap">
               <template>
                    <el-table :data="dataList" style="width: 100%"  height="320">
                         <!-- <el-table-column type="selection" width="46"></el-table-column> -->
                         <el-table-column prop="id" label="ID" width="70"></el-table-column>
                         <el-table-column label="批注人" width="80">
						<template slot-scope="scope">
							{{ scope.row.annotation ? scope.row.annotation.nickname : '/' }}
						</template>
					</el-table-column>
                         <el-table-column prop="remark"   label="批注内容"  width="200"></el-table-column>
                         <el-table-column prop="createtime_text"  width="160" label="批注时间"></el-table-column>
                         <el-table-column label="审核状态" width="80">
						<template slot-scope="scope">
							<span v-if="scope.row.apply_status == 0">未审核</span>
							<span class="c_6d9fff" v-if="scope.row.apply_status == 1">已审核</span>
						</template>
					</el-table-column>
                         <el-table-column width="80" label="审核人">
                              <template slot-scope="scope">
							{{ scope.row.apply ? scope.row.apply.nickname : '/' }}
						</template>
                         </el-table-column>
                         <el-table-column fixed="right" align="left" label="操作" width="140">
                              <template slot-scope="scope">
                                   <span @click="edit(scope.row.remark,scope.row.id)" class="cwBtn c_6d9fff pointer_ m_r10">修改</span>
                                   <span @click="del(scope.row.id)" class="cwBtn c_ff6f6f pointer_">删除</span>
                              </template>
                         </el-table-column>
                    </el-table>
               </template>
          </div>
          <!-- 批注子弹窗 -->
          <financeNodeModal ref="financeNodeModal" @ok="handlerNodeOk"></financeNodeModal>
     </el-dialog>
</template>

<script>
import {financialVerificationIndex,financialVerificationAdd,financialVerificationDelete,financialVerificationUpdata} from '@/api/index.js'
import financeNodeModal from '../finance-node-modal/finance-node-modal.vue';
export default {
  components: { financeNodeModal },
     data() {
          return {
               pageShow: false,
               dataList: [{
                    id: '001',
                    order_no: '112354578'
               }],
               subPageShow: false,//子弹窗
               content: "",
               curId:'',
               remark:'',
          };
     },
     methods: {
          open({id}){
               this.pageShow=true;
               this.curId=id;
               this.getData(id);
          },
          add() {
               this.$refs.financeNodeModal.open();
          },
          //获取财务批注列表
          getData(){
               financialVerificationIndex({orders_id:this.curId}).then(res=>{
                    console.log('获取财务批注列表',res)
                    this.dataList=res.data;
               })
          },
          // 获取批注内容
          handlerNodeOk(e){
               console.log('获取批注内容',e)
               this.remark=e.content;
               if(e.id){
                    console.log('编辑')
                    this.toEdit(this.remark,e.id);
               }else{
                  this.addData();  
               }
          },
          // 添加财务批注
          addData(){
               let params={
                    orders_id:this.curId,
                    remark:this.remark
               }
               financialVerificationAdd(params).then(res=>{
                    console.log('添加财务批注',res)
                    this.$message.success('批注添加成功');
                    this.getData();
               })
          },
          // 删除批注
          del(_id){
               console.log('删除批注id',_id)
               financialVerificationDelete({id:_id}).then(res=>{
                    console.log('删除批注',res)
                    this.$message.success('批注删除成功');
                    this.getData();
               })
          },
          // 更新财务批注
          edit(_val,_id){
               this.$refs.financeNodeModal.open({id:_id,content:_val})
          },
          toEdit(_val,_id){
               let params={
                    id:_id,
                    remark:_val
               }
               financialVerificationUpdata(params).then(res=>{
                    console.log('更新批注',res)
                    this.$message.success('批注更新成功');
                    this.getData();
               })
          }
     }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
     padding: 0 20px;
}
.table_wrap{ padding-bottom: 30px;}
</style>