<template>
	<div class="box01">
		<v-crumbs :crumbsType="30"></v-crumbs>
		<div class="offer_top flex02">
			<div class="flex03">
				<div class="btn01 m_r10" @click="$publicFun.get_link('/authority/role/add', {})">新增</div>
				<v-select02 :p_text="'名称'" :value_="formInfo.keyword" @serch="get_serch" @empty="get_empty"></v-select02>
			</div>
		</div>
		<div class="form_box">
			<template>
				<el-table ref="multipleTable" :data="list" style="width: 100%" @selection-change="handleSelectionChange">
					<el-table-column type="selection" fixed width="46"></el-table-column>
					<el-table-column prop="id" label="ID" width="70"></el-table-column>
					<el-table-column prop="name" label="名称"></el-table-column>
					<el-table-column prop="remark" label="说明"></el-table-column>
					<!-- <el-table-column label="状态" width="300">
						<template slot-scope="scope">
							<div>
								<el-switch style="display: block" v-model="scope.row.status" active-color="#5B8EF0" inactive-color="#ccc"></el-switch>
							</div>
						</template>
					</el-table-column> -->
					<el-table-column fixed="right" label="操作">
						<template slot-scope="scope">
							<div class="flex04">
								<p class="c_6d9fff m_r10 pointer_" @click="$publicFun.get_link('/authority/role/add?id=' + scope.row.id, {})">编辑</p>
								<p class="c_ff6f6f pointer_" @click="get_del(scope.row)">删除</p>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</template>
		</div>
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import select02 from '../../components/select/index01.vue'; // 搜索框

	import {
		showList,
		getAuthDel
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			'v-select02': select02
		},
		data() {
			return {
				list: [],
				detail_info: {},
				formInfo: {
					keyword: '' // 关键字
				},
			}
		},
		created() {
			this.get_list();
		},
		methods: {
			// 列表
			get_list() {
				showList(this.formInfo).then(res => {
					res.data.forEach((n, k) => {
						if (!n.remark) {
							n.remark = '-'
						}
					})

					this.list = res.data;
				})
			},
			// 搜索
			get_serch(e) {
				if (!e) return this.$message('请输入关键字');

				this.formInfo.keyword = e;
				this.get_list();
			},
			// 清空
			get_empty() {
				this.formInfo.keyword = '';
				this.get_list();
			},
			// 删除
			get_del(e) {
				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					getAuthDel({
						id: e.id
					}).then(res => {
						this.get_list();
						this.$message.success('删除成功');
					})
				})
			},
			handleSelectionChange(e) {

			},
		}
	}
</script>

<style lang="less" scoped>
	.offer_top {
		margin-bottom: 15px;
	}
</style>
