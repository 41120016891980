<template>
	<div class="box01">
		<v-crumbs :crumbsType='14'></v-crumbs>
		<div>
			<div class="form_wrapper">
				<div class="form_wrapper_left">
					<div class="form_wrapper_block hid_" style="margin-bottom: 25px;">
						<div class="form_foot">
							<a :href="excel_url" target="_blank" style="text-decoration:none;" class="btn01">导出 excel</a>
							<div class="btn01" style="margin-left: 15px;" @click="get_search">查询</div>
						</div>
						<div class="info_wrapper">
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<div class="form_choose_content flex01">
										<div class="form_choose_block" :class="{'active': business_info.type_id == 1}">
											<i @click="get_times(1)"></i>
											<p>本月</p>
										</div>
										<div class="form_choose_block" :class="{'active': business_info.type_id == 2}">
											<i @click="get_times(2)"></i>
											<p>周期:</p>
										</div>
									</div>
									<div class="form_input w_270">
										<el-date-picker v-enter-next-input v-model="date_" value-format="yyyy-MM-dd" type="daterange" range-separator="-"
										 start-placeholder="开始日期" end-placeholder="结束日期" @change="get_date"></el-date-picker>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">负责人:</p>
									<div class="form_input w_270">
										<el-input v-enter-next-input v-model="business_info.bidder" placeholder="请填写负责人" @input="get_input"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">销售人员:</p>
									<div class="form_input w_270">
										<el-input v-enter-next-input v-model="business_info.sales_person" placeholder="请填写销售人员" @input="get_input"></el-input>
										<!-- <el-select v-model="business_info.sales_person_id" filterable placeholder="请选择">
											<el-option v-for="item in saleUserList" :key="item.id" :label="item.nickname" :value="item.id"></el-option>
										</el-select> -->
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">客户名称:</p>
									<div class="form_input w_270">
										<v-select v-enter-next-input :icon_num="true" :list="kehu_list" :index="1" :value_name="kehu_name" @result="get_kehu_serch"></v-select>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">起运地/起运港:</p>
									<div class="form_input w_270">
										<v-select v-enter-next-input :icon_num="true" :list="start_harbor_list" :index="2" :value_name="shipment_name" @result="get_kehu_serch"></v-select>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">目的地/目的港:</p>
									<div class="form_input w_270">
										<v-select v-enter-next-input :icon_num="true" :list="end_harbor_list" :index="3" :value_name="destination_name" @result="get_kehu_serch"></v-select>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">运单编号:</p>
									<div class="form_input w_270">
										<el-input v-enter-next-input v-model="business_info.waybill_no" placeholder="请填写运单编号" @input="get_input"></el-input>
									</div>
								</div>
							</div>
							<div class="info_wrapper_block">
								<div class="form_input_wrapper">
									<p class="form_input_label">供应商名称:</p>
									<div class="form_input w_270">
										<v-select v-enter-next-input :icon_num="true" :list="gongyingshang_list" :index="4" :value_name="gongyingshang_name" @result="get_kehu_serch"></v-select>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="form_box active">
						<template>
							<el-table ref="multipleTable" height="250" :data="list" style="width: 100%">
								<el-table-column prop="createtimes" label="日期" width="120"></el-table-column>
								<el-table-column prop="order_no" label="订单编号" width="140"></el-table-column>
								<el-table-column prop="company_id_name" label="客户名称"  width="200"></el-table-column>
								<el-table-column prop="service_type_id_name" label="服务类型" width="100"></el-table-column>
								<el-table-column prop="transport.name" label="运输方式" width="100"></el-table-column>
								<el-table-column prop="transportation.name" label="运输流向" width="100"></el-table-column>
								<el-table-column prop="weight" label="订单计重(KG)" width="130"></el-table-column>
								<el-table-column prop="bidder" label="负责人" width="110"></el-table-column>
								<el-table-column prop="sales_person" label="销售人员" width="110"></el-table-column>
								<el-table-column prop="waybill_no" label="运单编号" width="160"></el-table-column>
							</el-table>
						</template>
						<div class="pagination">
							<el-pagination background :page-size="business_info.limit" :current-page="business_info.page" layout="prev, pager, next, jumper"
							 :total="total" @current-change="currentChange"></el-pagination>
						</div>
					</div>
					<div class="form_wrapper_block">
						<div class="ECharts_wrapper">
							<div class="ECharts_title font_16 c_fff">
								<div class="ECharts_block" :class="{'active': business_info.show_type == 1}">
									<i @click="get_eachar_type(1)"></i>
									<p>服务类型</p>
								</div>
								<div class="ECharts_block" :class="{'active': business_info.show_type == 2}">
									<i @click="get_eachar_type(2)"></i>
									<p>运输方式</p>
								</div>
								<div class="ECharts_block" :class="{'active': business_info.show_type == 3}">
									<i @click="get_eachar_type(3)"></i>
									<p>运输流向</p>
								</div>
							</div>
							<div class="ECharts_content flex03">
								<div style="width: 400px;height: 400px;">
									<div id="ECharts01" style="height: 100%"></div>
								</div>
								<div class="" style="flex: 1;">
									<div id="ECharts02" style="height: 100%"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="form_wrapper_right form_wrapper_block hid_" style="padding: 0 1%;">
					<div class="form_wrapper_right_block flex02">
						<div class="c_fff">
							<p class="font_20 m_b16">本月报价数量</p>
							<div class="flex05">
								<p class="bold_ m_r8" style="font-size: 36px;line-height: 47px;">{{month_data.offer_num}}</p>
								<p class="font_16" style="margin-bottom: 6px;">个</p>
							</div>
						</div>
						<img src="../../assets/echarts/05@2x.png" />
					</div>
					<div class="form_wrapper_right_block flex02">
						<div class="c_fff">
							<p class="font_20 m_b16">本月订单数量</p>
							<div class="flex05">
								<p class="bold_ m_r8" style="font-size: 36px;line-height: 47px;">{{month_data.order_num}}</p>
								<p class="font_16" style="margin-bottom: 6px;">个</p>
							</div>
						</div>
						<img src="../../assets/echarts/06@2x.png" />
					</div>
					<div class="form_wrapper_right_block flex02">
						<div class="c_fff">
							<p class="font_20 m_b16">本月新增客户量</p>
							<div class="flex05">
								<p class="bold_ m_r8" style="font-size: 36px;line-height: 47px;">{{month_data.company_num}}</p>
								<p class="font_16" style="margin-bottom: 6px;">个</p>
							</div>
						</div>
						<img src="../../assets/echarts/07@2x.png" />
					</div>
					<div class="form_wrapper_right_block flex02">
						<div class="c_fff">
							<p class="font_20 m_b16">本月订单毛重</p>
							<div class="flex05">
								<p class="bold_ m_r8" style="font-size: 36px;line-height: 47px;">{{month_data.total_weight}}</p>
								<p class="font_16" style="margin-bottom: 6px;">KG</p>
							</div>
						</div>
						<img src="../../assets/echarts/08@2x.png" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import select05 from '../../components/select/index05.vue'; // 模糊查询

	import {
		excel,
		business,
		companyList,
		startHarbor,
		endHarbor,
		showBusinessEchartData,getSaleUserList
	} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			'v-select': select05
		},
		data() {
			return {
				excel_url: '',
				date_: '',
				list: [],
				line_id: '',
				line_chart_name: '',
				kehu_name: '', // 客户name
				shipment_name: '', // 起运地name
				destination_name: '', // 目的地name
				gongyingshang_name: '', // 供应商name
				total: null,
				qita_list: [],
				kehu_list: [],
				echart_data: {},
				month_data: {},
				start_harbor_list: [],
				end_harbor_list: [],
				gongyingshang_list: [],
				business_info: {
					page: 1,
					limit: 10,
					type_id: 1, // 1本月 2：周期
					start_time: '', // 开始时间
					end_time: '', // 结束时间
					bidder: '', // 负责人
					company_id: '', // 客户名称
					shipment_id: '', // 起运地
					destination_id: '', // 目的地
					business_id: '', // 供应商
					show_type: 1, // 显示类型1 服务类型 2运输方式 3运输流向
					waybill_no:"",//运单编号
					sales_person:'',//销售人员
				},
				chart_info: {
					type_id: 1, // 1本月 2：周期
					start_time: '',
					end_time: '',
					show_type: 1, // 显示类型1 服务类型 2运输方式 3运输流向
				},
				saleUserList:[],//销售人员列表
			}
		},
		created() {
			this.getSaleUser();
			this.get_int();
			this.getCompanyList();
			this.getStartHarborList();
			this.getEndHarborList();

			this.get_url_();
		},
		methods: {
			// 获取销售人员列表
			getSaleUser(){
				getSaleUserList({}).then(res=>{
					console.log("获取销售人员列表",res)
					this.saleUserList=res.data;
				})
			},
			get_url_() {
				this.excel_url = this.$publicFun.get_excel() + '?export_id=1&type_id=' + this.business_info.type_id +
					'&start_time=' + this.business_info
					.start_time + '&end_time=' + this.business_info.end_time + '&bidder=' + this.business_info.bidder  + '&sales_person=' + this.business_info.sales_person + '&company_id=' +
					this.business_info.company_id + '&shipment_id=' + this.business_info.shipment_id + '&destination_id=' + this.business_info
					.destination_id + '&business_id=' + this.business_info.business_id;
			},
			get_int() {
				business(this.business_info).then(res => {
					res.data.order_list.data.forEach((n, k) => {
						if (!n.company_id_name) {
							n.company_id_name = '-'
						}

						if (!n.service_type_id_name) {
							n.service_type_id_name = '-'
						}

						if (!n.total_weight) {
							n.total_weight = '-'
						}
					})

					if (!this.month_data.order_num) {
						this.month_data = res.data.month_data;
					}

					this.echart_data = res.data.echart_data;

					if (res.data.echart_data.roundness.length > 0) {
						this.line_id = res.data.echart_data.roundness[0].id;
						this.line_chart_name = res.data.echart_data.roundness[0].name;
					} else {
						this.line_id = '';
						this.line_chart_name = '';
					}

					this.get_echart01();
					this.get_echart02();

					this.list = res.data.order_list.data;
					this.total = res.data.order_list.total;
				})
			},
			// 点击页码数
			currentChange(newPage) {
				this.business_info.page = newPage;
				this.get_int();
			},
			// 客户名称/供应商、其他
			getCompanyList() {
				companyList({
					page: 1,
					limit: 100000,
					partner_type: 1 // 供应商
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.gongyingshang_list.push({
							label: n.company_name,
							value: n.id
						})
					})
				})

				companyList({
					page: 1,
					limit: 100000,
					partner_type: 2 // 客户
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.kehu_list.push({
							label: n.company_name,
							value: n.id
						})
					})
				})
			},
			// 获取起运港列表
			getStartHarborList() {
				startHarbor({
					page: 1,
					limit: 100000
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.start_harbor_list.push({
							label: n.name + '/' + n.code,
							value: n.id
						})
					})
				})
			},
			// 获取目的港列表
			getEndHarborList() {
				endHarbor({
					page: 1,
					limit: 100000
				}).then(res => {
					let list_ = res.data.data;

					list_.forEach((n, k) => {
						this.end_harbor_list.push({
							label: n.name + '/' + n.code,
							value: n.id
						})
					})
				})
			},
			// 日期
			get_date(e) {
				this.business_info.start_time = e[0];
				this.business_info.end_time = e[1];
				this.chart_info.start_time = e[0];
				this.chart_info.end_time = e[1];

				this.get_url_();
			},
			get_input(e) {
				this.get_url_();
			},
			get_kehu_serch(e) {
				if (e.index === 1) {
					// 客户
					this.kehu_name = e.label;
					this.business_info.company_id = e.value;
				}

				if (e.index === 2) {
					// 起运地
					this.shipment_name = e.label;
					this.business_info.shipment_id = e.value;
				}

				if (e.index === 3) {
					// 目的地
					this.destination_name = e.label;
					this.business_info.destination_id = e.value;
				}

				if (e.index === 4) {
					// 供应商
					this.gongyingshang_name = e.label;
					this.business_info.business_id = e.value;
				}

				this.get_url_();
			},
			get_times(index) {
				this.business_info.type_id = index;
				this.chart_info.type_id = index;

				this.get_url_();
			},
			// 查询
			get_search() {
				console.log('查询',this.business_info)
				this.business_info.page = 1;
				this.get_int();
			},
			// 图标上方按钮
			get_eachar_type(index) {
				this.chart_info.show_type = index;
				this.business_info.show_type = index;

				showBusinessEchartData(this.chart_info).then(res => {
					this.echart_data = res.data;

					this.line_id = res.data.roundness[0].id;
					this.line_chart_name = res.data.roundness[0].name;

					this.get_echart01();
					this.get_echart02();
				})
			},
			// 图表
			get_echart01() {
				var chartDom = document.getElementById('ECharts01');
				var myChart = echarts.init(chartDom);
				var option;

				option = {
					tooltip: {
						trigger: 'item'
					},
					legend: {
						show: false, // 不显示
						orient: 'vertical',
						left: 'left',
					}, // 显示颜色代表什么
					color: ['#44B549', '#0D8BBB', '#FFC68B', '#F67F04'],
					series: [{
						name: '访问来源',
						type: 'pie',
						radius: ['35%', '50%'], // 饼图的半径，第一个为内半径，第二个为外半径
						data: this.echart_data.roundness,
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						},
						label: {
							normal: {
								formatter: '{b|{b}}', // 图形外文字
								borderWidth: 20,
								borderRadius: 4,
								color: '#fff',
								rich: {
									b: { // name 文字样式
										fontSize: 14,
										lineHeight: 20,
										color: '#fff'
									},
								}
							}
						},
					}]
				};

				option && myChart.setOption(option);
				myChart.on("click", this.eConsole);
			},
			eConsole(e) {
				this.line_id = e.data.id;
				this.line_chart_name = e.data.name;
				this.get_echart02();
			},
			get_echart02(e) {
				var chartDom = document.getElementById('ECharts02');
				var myChart = echarts.init(chartDom);
				var option;
				var arrData = []; // 数据
				var arrDateData = []; // 日期

				for (let i in this.echart_data.echart_data) {
					if (i == this.line_id) {
						arrData = this.echart_data.echart_data[i].values
						arrDateData = this.echart_data.echart_data[i].times
					}
				}

				option = {
					title: {
						text: '订单数量（' + this.line_chart_name + '）',
						textStyle: {
							fontWeight: 'normal', // 标题颜色
							color: '#fff',
							x: 'left'
						},
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						splitLine: {
							show: true,
							lineStyle: {
								color: '#44566A',
							}
						},
						data: arrDateData
					},
					yAxis: {
						type: 'value',
						splitLine: {
							show: true,
							lineStyle: {
								color: '#44566A',
							}
						}
					},
					series: [{
						color: '#46CCFF',
						symbolSize: 10, // 设定实心点的大小
						data: arrData,
						type: 'line',
						smooth: true,
						areaStyle: {},
					}]
				};

				option && myChart.setOption(option);
			}
		}
	}
</script>

<style lang="less" scoped>
	.info_wrapper {
		padding: 12px 9px;
	}

	/deep/ .form_box .el-table .el-table__header-wrapper th {
		background-color: rgb(51, 73, 111);
	}

	/deep/ input::-webkit-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input::-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-moz-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ input:-ms-input-placeholder,
	/deep/ textarea::-webkit-input-placeholder {
		color: #5B8EF0;
	}

	/deep/ .el-range-input {
		color: #fff;
		font-size: 12px;
		background-color: transparent;
	}

	/deep/ .el-date-editor .el-range-input {
		color: #fff;
		font-size: 12px;

		&::-webkit-input-placeholder {
			color: #5B8EF0;
		}

		&::-moz-placeholder {
			color: #5B8EF0;
		}

		&:-moz-placeholder {
			color: #5B8EF0;
		}

		&:-ms-input-placeholder {
			color: #5B8EF0;
		}

	}

	/deep/ .el-date-editor--daterange.el-input__inner {
		width: 100%;
	}

	/deep/ .el-date-editor .el-range-separator {
		color: #fff;
	}

	.form_wrapper .info_wrapper_block {
		margin-top: 11px;
		margin-bottom: 11px;
	}

	.form_choose_content {
		width: 130px;

		.form_choose_block {
			min-width: 0;
			margin-right: 0;

			&:first-child {
				margin-right: 16px;
			}
		}
	}

	.form_input_label {
		padding-right: 10px;
		text-align: right;
		width: 130px !important;
		box-sizing: border-box;
	}

	.form_input.w_270 {
		width: 12vw !important;
	}

	.form_foot {
		display: flex;
		padding-left: 18px;
		padding-right: 48px;
		margin-top: 23px;
		justify-content: flex-start;
	}

	.form_wrapper_right_block {
		padding: 9vh 13%;
		margin: 3vh 0;
		border-radius: 10px;

		img {
			width: 65px;
		}

		&:first-child {
			background: url(../../assets/echarts/01@2x.png) no-repeat center;
			background-size: 100% 100%;
		}

		&:nth-child(2) {
			background: url(../../assets/echarts/02@2x.png) no-repeat center;
			background-size: 100% 100%;
		}

		&:nth-child(3) {
			background: url(../../assets/echarts/03@2x.png) no-repeat center;
			background-size: 100% 100%;
		}

		&:nth-child(4) {
			background: url(../../assets/echarts/04@2x.png) no-repeat center;
			background-size: 100% 100%;
		}
	}

	.ECharts_wrapper {
		.ECharts_title {
			padding: 35px 50px;
			display: flex;

			.ECharts_block {
				display: flex;
				align-items: center;
				margin-right: 16px;

				&.active {
					i {
						border: 3.5px solid #64B2FF;
					}
				}

				i {
					width: 16px;
					height: 16px;
					display: block;
					border-radius: 50%;
					margin-right: 7px;
					cursor: pointer;
					box-sizing: border-box;
					border: 1px solid #FFFFFF;
				}
			}
		}
	}
</style>
