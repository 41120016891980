<template>
	<div class="box01">
		<v-crumbs :crumbsType="190" @get_mxy="get_mxy"></v-crumbs>
		<div class="offer_top flex02">
			<div class="flex03">
				<v-select02 :p_text="'文件名称'" :value_="formInfo.file_name" @serch="get_serch" @empty="get_empty"></v-select02>
			</div>
		</div>
		<div class="form_box">
			<template>
				<el-table ref="multipleTable" :data="list" style="width: 100%">
					<el-table-column prop="id" label="ID" width="70"></el-table-column>
					<el-table-column prop="file_name" label="文件名称" ></el-table-column>
          <el-table-column  :width="170" label="订单类型">
          	<template slot-scope="scope">
          		<span v-if="scope.row.type_id === 1">应收</span>
          		<span v-if="scope.row.type_id === 2">应付</span>
          	</template>
          </el-table-column>
					<el-table-column prop="createtime_text" label="时间"></el-table-column>
					<el-table-column  :width="170" label="状态">
						<template slot-scope="scope">
							<v-select01 :width="100" style="margin: 0 auto;display: block;" :p_text="''" :v_text="scope.row.status_text" :option="selectOption" @serch="get_option($event, scope.row.id)"></v-select01>
						</template>
					</el-table-column>
          <el-table-column  :width="120" label="文件">
          	<template slot-scope="scope">
          		<!-- <span style="cursor: pointer;" @click="birthdEx(scope.row)">生成Excel</span> -->
              <a style="color: #fff;text-decoration: none;" :href="downHttp+scope.row.id" target="_blank" download="">生成Excel</a>
          	</template>
          </el-table-column>
					<el-table-column label="操作" width="220" fixed="right">
						<template slot-scope="scope">
							<div class="flex04">
								<p class="c_6d9fff m_r10 pointer_" @click="$publicFun.get_link('/settle/collect/show?id=' + scope.row.id+'&type=' + scope.row.type_id, {})">查看</p>
								<p class="c_ff6f6f pointer_" @click="get_del(scope.row)">删除</p>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</template>
		</div>
		<div class="pagination">
			<el-pagination background :page-size="formInfo.limit" :current-page="formInfo.page" layout="prev, pager, next, jumper, sizes"
			 :total="total" :page-sizes="[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]" @current-change="currentChange" @size-change="handleSizeChange"></el-pagination>
		</div>
	</div>
</template>

<script>
	import crumbs from '../../components/crumbs/index.vue'; // 面包屑
	import select02 from '../../components/select/index01.vue'; // 搜索框
	import select01 from '../../components/select/index.vue'; // 选择器

	import {getCollectList,setCollectStatus,delCollect,exportExcelApi,downHttpApi} from '@/api/index';

	export default {
		components: {
			'v-crumbs': crumbs,
			'v-select01': select01,
			'v-select02': select02,
		},
		data() {
			return {
				list: [],
				total: null,
				choose_id: [],
				detail_info: {},
				selectOption: [{
					value: 1,
					label: '未完成'
				}, {
					value: 2,
					label: '已完成'
				}], // 下拉选择框
				formInfo: {
					page: 1,
					limit: 10,
					file_name: '', // 文件名称
				},
        downHttp:''
			}
		},
		created() {
			this.get_list();
      this.downHttp = downHttpApi()
      
		},
		methods: {
      birthdEx(e){
        console.log(e)
        exportExcelApi({sid:e.id}).then(res=>{
          console.log('66666',res)
        })
      },
			get_mxy() {
				this.formInfo = {
					page: 1,
					limit: 10,
					file_name: '', // 文件名称
				}
				this.get_list();
			},
			// 列表
			get_list() {
				getCollectList(this.formInfo).then(res => {
					res.data.data.forEach((n, k) => {
						if (!n.remark) {
							n.remark = '-';
						}
					})
					this.list = res.data.data;
					this.total = res.data.total;
				})
			},
			// 更新状态
			get_option(e, id){
				setCollectStatus({
					id:id,
					status:e
				}).then(res => {
					this.get_list();
					this.$message.success('更新成功');
				})
			},
			
			// 清空搜索
			get_empty(e) {
				this.formInfo.page = 1;
				this.formInfo.keyword = '';
			
				this.get_list();
			},
			
			// 点击页码数
			currentChange(newPage) {
				this.formInfo.page = newPage;
				this.get_list();
				window.scroll(0, 0);
			},
			// 点击一页请求数据
			handleSizeChange(num) {
				this.formInfo.page = 1;
				this.formInfo.limit = num;
				this.get_list();
				window.scroll(0, 0);
			},
			// 搜索
			get_serch(e) {
				if (!e) return this.$message('请输入文件名称');

				this.formInfo.page = 1;
				this.formInfo.file_name = e, // 文件名称 = e;
				this.get_list();
			},
		
			// 删除
			get_del(e) {
				this.$confirm('确定删除吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					delCollect({
						id: e.id,
					}).then(res => {
						if (this.formInfo.page != 1 && this.list.length == 1) {
							this.formInfo.page--
						}
						this.get_list();
						this.$message.success('删除成功');
					})
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.offer_top {
		margin-bottom: 15px;
	}
</style>
